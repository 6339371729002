import React, { Component } from "react";

class Input extends Component {
    render() {
        const { name, label, error, ...rest } = this.props;
        return (
            <div className="form-group row fv-plugins-icon-container has-danger">
                <div className="col-lg-12">
                    <label htmlFor={name}>{label}</label>
                    <input name={name} className="form-control" {...rest} step={1} />
                    {error && (
                        <div className="fv-plugins-message-container">
                            <div data-validator="notEmpty" className="fv-help-block">
                                {error}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Input;
