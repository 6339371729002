import React, { Component } from "react";
import moment from "moment";

class EventDetail extends Component {
  render() {
    const detail = this.props.detail;
    return (
      <>
        <div
          className={
            this.props.detailModal
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Event Detail</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleDetail}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              width={300}
              height={200}
              src={
                detail.coverImage &&
                process.env.REACT_APP_CDN + detail.coverImage.small
              }
              alt=""
            />
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Event Title:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.title}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Event Type:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.type === "non-recurring"
                ? "Single Day Event"
                : "Recurring Event"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Start Date:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.startDate
                ? moment(detail.startDate).format("DD MMMM YYYY")
                : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Start Time :{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.startDate
                ? moment(detail.startDate).format("hh:mm A")
                : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              End Date:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.endDate
                ? moment(detail.endDate).format("DD MMMM YYYY")
                : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              End Time:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.endDate ? moment(detail.endDate).format("hh:mm A") : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Event Status:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.eventStatus
                ? detail.eventStatus === "accepted"
                  ? "Accepted"
                  : detail.eventStatus === "pending"
                  ? "Pending"
                  : "Rejected"
                : "Pending"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Description:{" "}
            </a>

            {detail.description &&
              detail.description.split("\n").map((el, i) => (
                <span
                  key={i}
                  className="text-muted font-weight-bold education-margin"
                  style={{ whiteSpace: "normal" }}
                >
                  {el}
                </span>
              ))}
          </div>
        </div>
        {this.props.detailModal ? (
          <div
            className="offcanvas-overlay"
            onClick={this.props.toggleDetail}
          ></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default EventDetail;
