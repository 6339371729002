import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";
import {
  addEvent,
  editEvent,
  loadEvents,
  uploadCoverImage,
} from "../../store/events";
import close from "../../include/media/close.svg";

const initialState = {
  loading: false,
  type: "",
  reccuringType: "none",
  image: {},
  imageName: "",
  data: {
    description: "",
    title: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  },
  errors: {},
  browserLabel: "Browse",
  disable: false,
};
class AddEvent extends Form {
  state = initialState;

  schema = {
    title: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Event Title is required.";
              break;
          }
        });
        return errors;
      }),
    startDate: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Start Date is required.";
              break;
            case "date.base":
              err.message = "Start Date is required.";
              break;
          }
        });
        return errors;
      }),
    startTime: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Start Time is required.";
              break;
            case "date.base":
              err.message = "Start Time is required.";
              break;
          }
        });
        return errors;
      }),
    endDate: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "End Date is required.";
              break;
            case "date.base":
              err.message = "End Date is required.";
              break;
          }
        });
        return errors;
      }),
    endTime: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "End Time is required.";
              break;
            case "date.base":
              err.message = "End Time is required.";
              break;
          }
        });
        return errors;
      }),

    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Description is required.";
              break;
          }
        });
        return errors;
      }),
  };

  uploadFile = async (e) => {
    this.setState({
      loading: true,
      browserLabel: "Uploading...",
      disabled: true,
    });
    this.setState({ imageName: e.target.files[0].name });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadCoverImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ image: res.data.data });
      delete errors.image;
      this.setState({
        loading: false,
        errors,
        browserLabel: "Browse",
        disable: false,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.edit == false && this.props.edit !== prevProps.edit) {
      this.setState({
        loading: false,
        type: "",
        reccuringType: "none",
        image: {},
        data: {
          title: "",
          description: "",
          startDate: "",
          startTime: "",
          endTime: "",
          endDate: "",
        },
        browserLabel: "Browse",
        disable: false,
        errors: {},
      });
      this.props.setEditDataNull();
    }
    if (
      this.props.edit == true &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = { ...this.state.data };
      let image = {};
      let type = "";
      let reccuringType = "";
      image = this.props.editData && this.props.editData.coverImage;
      type = this.props.editData && this.props.editData.type;
      reccuringType = this.props.editData && this.props.editData.recurringType;
      data.title = this.props.editData && this.props.editData.title;
      data.startDate = new Date(
        this.props.editData && this.props.editData.startDate
      );
      data.endDate = new Date(
        this.props.editData && this.props.editData.endDate
      );
      data.startTime = new Date(
        this.props.editData && this.props.editData.startDate
      );
      data.endTime = new Date(
        this.props.editData && this.props.editData.endDate
      );
      data.description = this.props.editData && this.props.editData.description;
      this.setState({ data, image, type, reccuringType });
    }
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    const { errors } = this.state;
    const data = { ...this.state.data };
    if (_.isEmpty(this.state.image)) {
      errors.image = "Cover Image is required";
    }
    // if (!this.state.type) {
    //   errors.type = "Event Type is required";
    // }
    this.setState({ errors, loading: false });
    let payload = {
      title: data.title,
      type: "non-recurring",
      description: data.description,
      recurringType:
        this.state.type === "recurring" ? this.state.reccuringType : "none",
      startDate: moment(
        moment(data.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
          " " +
          moment(data.startTime, "h:mm a").format("h:mm a"),
        "YYYY-MM-DD h:mm a"
      ).format("x"),
      endDate: moment(
        moment(data.endDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
          " " +
          moment(data.endTime, "h:mm a").format("h:mm a"),
        "YYYY-MM-DD h:mm a"
      ).format("x"),
      coverImage: this.state.image,
    };
    if (_.isEmpty(this.state.errors)) {
      if (this.props.edit == true) {
        this.props.editEvent(
          this.props.editData && this.props.editData._id,
          payload,
          (res) => {
            this.setState({ loading: false });
            if (res && res.status === 200) {
              this.props.loadEvents({
                sort: this.props.sort,
                order: this.props.order,
                keyword: this.props.keyword,
              });
              this.toggleAddClose();
              return toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              return toast(
                <AlertError message={res && res.data && res.data.message} />
              );
            }
          }
        );
      } else {
        this.props.addEvent(payload, (res) => {
          this.setState({ loading: false });
          if (res && res.status === 200) {
            this.props.loadEvents({
              sort: this.props.sort,
              order: this.props.order,
              keyword: this.props.keyword,
            });
            this.toggleAddClose();
            return toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            return toast(
              <AlertError message={res && res.data && res.data.message} />
            );
          }
        });
      }
    }
  };

  toggleAddClose = () => {
    this.setState(initialState);
    if (!this.props.edit) {
      this.props.toggleAddEvent();
    } else {
      this.props.toggleEditEvent();
    }
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Event</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("title", "Title")}
            <div class="form-group">
              <label for="formFile" class="form-label">
                Select Image
              </label>
              <div class="upload-btn-wrapper d-flex justify-content-end">
                <button class="btn" style={{ cursor: "pointer" }}>
                  {this.state.browserLabel}
                </button>
                <input
                  style={{ cursor: "pointer" }}
                  type="file"
                  name="myfile"
                  onChange={this.uploadFile}
                />
              </div>
              {this.state.errors && this.state.errors.image && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.image}
                  </div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.image) && (
              <div class="form-group" style={{ width: "50%" }}>
                <div class="ui-box" style={{ position: "relative" }}>
                  <div class="ui-img">
                    <img
                      src={process.env.REACT_APP_CDN + this.state.image.small}
                      b
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => this.setState({ image: {} })}
                    class="close-img"
                    style={{
                      position: "absolute",
                      top: "-6%",
                      right: "-6%",
                      background: "#fff",
                      borderRadius: "50%",
                      height: "22px",
                      width: "22px",
                      verticalAlign: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>
            )}
            {this.renderDateInput("startDate", "Start Date", new Date())}
            {this.renderTimeInput("startTime", "Start Time")}
            {this.renderDateInput("endDate", "End Date", new Date())}
            {this.renderTimeInput("endTime", "End Time")}
            {this.renderTextarea("description", "Description")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadCoverImage: (data, callback) =>
    dispatch(uploadCoverImage(data, callback)),
  addEvent: (data, callBack) => dispatch(addEvent(data, callBack)),
  editEvent: (id, data, callBack) => dispatch(editEvent(id, data, callBack)),
  loadEvents: (data, callback) => dispatch(loadEvents(data, callback)),
});
export default connect(null, mapDispatchToProps)(AddEvent);
