export const clearAllToken = () => {
  localStorage.removeItem("jwt");
};

export const setJwt = (jwt, refresh) => {
  if (refresh) localStorage.setItem("x-refresh-token", refresh);
  localStorage.setItem("jwt", jwt);
};

export const getJWT = () => {
  return localStorage.getItem("jwt");
};

export const setAdminId = (id) => {
  localStorage.setItem("adminId", id);
};

export const getAdminId = () => {
  return localStorage.getItem("adminId");
};

export const setAdminName = (name) => {
  localStorage.setItem("adminName", name);
};

export const getAdminName = () => {
  return localStorage.getItem("adminName");
};

export const setAdminEmail = (name) => {
  localStorage.setItem("adminEmail", name);
};

export const getAdminEmail = () => {
  return localStorage.getItem("adminEmail");
};

export const setAdminRole = (role) => {
  localStorage.setItem("adminRole", role);
};

export const getAdminRole = () => {
  return localStorage.getItem("adminRole");
};
