import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  addTopic,
  editForumTopic,
  getForum,
  getFoumCatergory,
  searchForumTopic,
  uploadCoverImage,
  uploadGridImage,
} from "../../../store/forum";
import Form from "../../common/form/form";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import close from "../../../include/media/close.svg";

class AddTopic extends Form {
  state = {
    tags: [],
    category: "",
    loading: false,
    gridLabel: "Browse",
    coverLabel: "Browse",
    vidType: "",
    data: {
      topic: "",
      shortDescription: "",
      longDesription: "",
      url: "",
    },
    errors: {},
    disabled: false,
    gridImage: {},
    coverImage: {},
    catName: "",
  };

  schema = {
    topic: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Topic is required.";
              break;
          }
        });
        return errors;
      }),
    shortDescription: Joi.string()
      .max(140)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Summary is required.";
              break;
            case "string.max":
              err.message =
                "Summary should not be greater than 140 characters.";
              break;
          }
        });
        return errors;
      }),
    longDesription: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Forum Text is required.";
              break;
          }
        });
        return errors;
      }),
    url: Joi.string().allow(""),
  };
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (this.props.isShow && this.props.isShow !== prevProps.isShow) {
  //   }
  // };

  componentDidMount = () => {
    this.props.getFoumCatergory();
  };

  toggleAddClose = () => {
    // this.setState(initialState);
    if (!this.props.edit) {
      this.props.toggleAddTopic();
    } else {
      this.props.toggleEditForum();
    }
  };

  uploadGridFile = async (e) => {
    this.setState({
      loading: true,
      disabled: true,
      gridLabel: "uploading...",
    });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadGridImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ gridImage: res.data.data });
      delete errors.gridImage;
      this.setState({
        loading: false,
        errors,
        disabled: false,
        gridLabel: "Browse",
      });
    });
  };

  uploadCoverFile = async (e) => {
    this.setState({
      loading: true,
      disabled: true,
      coverLabel: "uploading...",
    });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadCoverImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ coverImage: res.data.data });
      delete errors.coverImage;
      this.setState({
        loading: false,
        errors,
        disabled: false,
        coverLabel: "Browse",
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.edit == false && this.props.edit !== prevProps.edit) {
      this.setState({
        tags: [],
        category: "",
        loading: false,
        gridLabel: "Browse",
        coverLabel: "Browse",
        vidType: "",
        data: {
          topic: "",
          shortDescription: "",
          longDesription: "",
          url: "",
        },
        errors: {},
        disabled: false,
        gridImage: {},
        coverImage: {},
      });
      this.props.setEditDataNull();
    }
    if (
      this.props.edit == true &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = { ...this.state.data };
      let gridImage = {};
      let coverImage = {};
      let tags = [];
      let category = "";
      let vidType = "";
      let catName = "";
      gridImage = this.props.editData && this.props.editData.gridImage;
      coverImage = this.props.editData && this.props.editData.coverImage;
      tags =
        this.props.editData &&
        this.props.editData.tags.map((el) => ({
          label: el,
          value: el,
        }));
      category =
        this.props.editData &&
        this.props.editData.category &&
        this.props.editData.category._id;
      catName =
        this.props.editData &&
        this.props.editData.category &&
        this.props.editData.category.name;
      if (this.props.editData && this.props.editData.video) {
        vidType =
          this.props.editData &&
          this.props.editData.video &&
          this.props.editData.video.type;
        data.url =
          this.props.editData &&
          this.props.editData.video &&
          this.props.editData.video.url;
      }
      data.topic = this.props.editData && this.props.editData.name;

      data.shortDescription =
        this.props.editData && this.props.editData.shortDescription;

      data.longDesription =
        this.props.editData && this.props.editData.longDescription;
      this.setState({
        data,
        gridImage,
        coverImage,
        tags,
        category,
        vidType,
        catName,
      });
    }
  };

  doSubmit = () => {
    this.setState({ loading: true, disabled: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    // if (!_.isEmpty(data.url) && !isURL(data.url)) {
    //   errors.url = "Please enter a valid URL";
    // }
    if (_.isEmpty(this.state.gridImage)) {
      errors.gridImage = "Grid Image is required";
    }
    if (_.isEmpty(this.state.coverImage)) {
      errors.coverImage = "Cover Image is required";
    }
    if (_.isEmpty(this.state.tags)) {
      errors.tags = "Tags is required";
    }

    if (_.isEmpty(this.state.category)) {
      errors.category = "Category is required";
    }

    this.setState({ errors, loading: false, disabled: false });
    let payload = {
      name: data.topic,
      category: this.state.category,
      tags: this.state.tags && this.state.tags.map((el) => el.value),
      shortDescription: data.shortDescription,
      longDescription: data.longDesription,
      gridImage: this.state.gridImage,
      coverImage: this.state.coverImage,
      // video: {
      //   type: this.state.vidType,
      //   url: data.url,
      // },
    };

    if (this.state.vidType !== "") {
      payload.video = {
        type: this.state.vidType,
        url: data.url,
      };
    }

    if (_.isEmpty(this.state.errors)) {
      if (this.props.edit) {
        this.props.editForumTopic(
          this.props.editData._id,
          payload,
          this.callback
        );
      } else {
        this.props.addTopic(payload, this.callback);
      }
    }
  };

  callback = (res) => {
    this.setState({ loading: false, disabled: false });
    if (res.status === 200) {
      this.setState({
        loading: false,
        disabled: false,
        tags: [],
        category: "",
        gridImage: {},
        coverImage: {},
        vidType: "",
        data: {
          topic: "",
          shortDescription: "",
          longDesription: "",
          url: "",
        },
        errors: {},
        catName: "",
      });
      this.toggleAddClose();
      this.props.searchForumTopic({
        order: this.props.order,
        sort: this.props.sort,
        keyword: this.props.keyword,
      });
      return toast(<AlertSuccess message={"Information Saved"} />);
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    const components = {
      DropdownIndicator: null,
    };
    const forumCategory = this.props.forumCategory;
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Topic</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("topic", "Topic Name")}
            <div class="form-group">
              <label class="form-label">Category</label>
              <select
                style={{
                  width: "100%",
                  height: "calc(1.5em + 1.3rem + 2px)",
                  borderRadius: "0.42rem",
                  border: "1px solid #e4e6ef",
                }}
                class="form-select"
                onChange={(e) => {
                  const errors = { ...this.state.errors };
                  e.preventDefault();
                  if (e.target.value) {
                    this.setState({ category: e.target.value });
                    delete errors.category;
                    this.setState({ errors });
                  } else {
                    errors.type = "Category is required";
                    this.setState({ errors });
                  }
                }}
              >
                <option value="">Select Category</option>
                {forumCategory &&
                  forumCategory.length > 0 &&
                  forumCategory
                    .filter((el) => el.isActive == true)
                    .map((fo, i) => (
                      <option
                        key={i}
                        value={fo._id}
                        selected={this.state.catName == fo.name}
                      >
                        {fo.name}
                      </option>
                    ))}
              </select>
              {this.state.errors && this.state.errors.category && (
                <label className="error">{this.state.errors.category}</label>
              )}
            </div>
            <div class="form-group">
              <label class="form-label">Tags</label>
              <CreatableSelect
                components={components}
                noOptionsMessage={() => "Type something and press enter..."}
                isMulti
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                placeholder="Type something and press enter..."
                name="tags"
                styles={this.customStyles}
                value={this.state.tags}
                onChange={(e) => {
                  const errors = { ...this.state.errors };
                  if (e && e.length > 0) {
                    console.log("e", e);
                    this.setState({ tags: e });
                    delete errors.tags;
                    this.setState({ errors });
                  } else if (e.length == 0) {
                    this.setState({ tags: "" });
                    errors.tags = "Tags are required";
                    this.setState({ errors });
                  }
                }}
              />
              {this.state.errors && this.state.errors.tags && (
                <label className="error">{this.state.errors.tags}</label>
              )}
            </div>
            {this.renderTextarea("shortDescription", "Summary")}
            {this.renderTextarea("longDesription", "Forum Text")}

            <div class="form-group">
              <label for="formFile" class="form-label">
                Select Grid Image
              </label>
              <div class="upload-btn-wrapper d-flex justify-content-end">
                <button style={{ cursor: "pointer" }} class="btn">
                  {this.state.gridLabel}
                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={this.uploadGridFile}
                />
              </div>
              {this.state.errors && this.state.errors.gridImage && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.gridImage}
                  </div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.gridImage) && (
              <div class="form-group" style={{ width: "50%" }}>
                <div class="ui-box" style={{ position: "relative" }}>
                  <div class="ui-img">
                    <img
                      src={
                        process.env.REACT_APP_CDN + this.state.gridImage.small
                      }
                      b
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => this.setState({ gridImage: {} })}
                    class="close-img"
                    style={{
                      position: "absolute",
                      top: "-6%",
                      right: "-6%",
                      background: "#fff",
                      borderRadius: "50%",
                      height: "22px",
                      width: "22px",
                      verticalAlign: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div class="form-group">
              <label for="formFile" class="form-label">
                Select Cover Image
              </label>
              <div class="upload-btn-wrapper d-flex justify-content-end">
                <button style={{ cursor: "pointer" }} class="btn">
                  {this.state.coverLabel}
                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={this.uploadCoverFile}
                />
              </div>
              {this.state.errors && this.state.errors.coverImage && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.coverImage}
                  </div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.coverImage) && (
              <div class="form-group" style={{ width: "50%" }}>
                <div class="ui-box" style={{ position: "relative" }}>
                  <div class="ui-img">
                    <img
                      src={
                        process.env.REACT_APP_CDN + this.state.coverImage.small
                      }
                      b
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => this.setState({ coverImage: {} })}
                    class="close-img"
                    style={{
                      position: "absolute",
                      top: "-6%",
                      right: "-6%",
                      background: "#fff",
                      borderRadius: "50%",
                      height: "22px",
                      width: "22px",
                      verticalAlign: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div class="form-group">
              <label for="name" class="form-label">
                Video
              </label>
              {/* <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Upload a Video
                            </label>
                          </div> */}
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="youtube"
                  onChange={() => {
                    this.setState({ vidType: "youtube" }, () => {
                      this.schema.url = Joi.string()
                        .required()
                        .error((errors) => {
                          errors.forEach((err) => {
                            switch (err.type) {
                              case "any.empty":
                                err.message = "URL is required.";
                                break;
                            }
                          });
                          return errors;
                        });
                    });
                  }}
                  checked={this.state.vidType === "youtube"}
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Youtube URL
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="vimeo"
                  onChange={() => {
                    this.setState({ vidType: "vimeo" }, () => {
                      this.schema.url = Joi.string()
                        .required()
                        .error((errors) => {
                          errors.forEach((err) => {
                            switch (err.type) {
                              case "any.empty":
                                err.message = "URL is required.";
                                break;
                            }
                          });
                          return errors;
                        });
                    });
                  }}
                  checked={this.state.vidType === "vimeo"}
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  Vimeo URL
                </label>
              </div>
            </div>
            {this.renderInput("url", "URL")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  forumCategory: getForum(state).forumCategory,
});
const mapDispatchToProps = (dispatch) => ({
  getFoumCatergory: (param) => dispatch(getFoumCatergory(param)),
  addTopic: (data, callback) => dispatch(addTopic(data, callback)),
  uploadGridImage: (data, callback) =>
    dispatch(uploadGridImage(data, callback)),
  uploadCoverImage: (data, callback) =>
    dispatch(uploadCoverImage(data, callback)),
  searchForumTopic: (param) => dispatch(searchForumTopic(param)),
  editForumTopic: (id, data, callback) =>
    dispatch(editForumTopic(id, data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTopic)
);
