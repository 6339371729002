import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
    name: "admin",
    initialState: {
        admin: {},
        loading: false,
        lastFetch: null,
    },
    reducers: {
        adminRequested: (admin, action) => {
            admin.loading = true;
        },
        adminReceived: (admin, action) => {
            admin.products = action.payload;
            admin.loading = false;
            admin.lastFetch = Date.now();
        },
        adminRequestFailed: (admin, action) => {
            admin.loading = false;
        },
    },
});

export const {
    adminRequested,
    adminReceived,
    adminRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "auth/admins";


export const adminSignIn = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};



export const getAdmin = createSelector(
    (state) => state.entities.admin,
    (admin) => admin
);