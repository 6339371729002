import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ModalPopup from "../common/modal";
import StatusModal from "../common/statusModal";
import UserDatail from "./userDetail";

class UserGrid extends Component {
  render() {
    console.log("adm", this.props.users);
    const { _id, role } = JSON.parse(localStorage.getItem("admin_details"));
    return (
      <>
        <tbody className="datatable-body">
          {this.props.users.map((e, i) => (
            <tr
              data-row="10"
              className="datatable-row"
              style={{ left: "0px" }}
              key={i}
            >
              <td className="datatable-cell" style={{ width: "18%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.firstName + " " + e.lastName}
                  </div>
                </span>
              </td>

              <td className="datatable-cell" style={{ width: "23%" }}>
                <span>
                  {/* <div className="font-weight-bolder font-size-lg mb-0">
                    {e.email}
                  </div> */}
                  <div className="d-dlex flex-column">
                    <div className="font-weight-bolder font-size-lg mb-0">
                      {e.email}
                    </div>
                    <div className="font-weight-bold text-muted">
                      {e.emailVerified ? "Verified" : "Not Verified"}
                    </div>
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "12%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.mobile ? e.mobile : "--"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.location ? e.location : "--"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "10%" }}>
                <span>
                  {e.isActive ? (
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.props.toggleAcitve(
                          e._id,
                          false,
                          "Are you sure you want to deactivate this record?"
                        );
                      }}
                    >
                      <span className="label label-lg font-weight-bold  label-light-success label-inline">
                        Active
                      </span>
                    </Link>
                  ) : (
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.props.toggleAcitve(
                          e._id,
                          true,
                          "Are you sure you want to activate this record?"
                        );
                      }}
                    >
                      <span className="label label-lg font-weight-bold  label-light-danger label-inline">
                        InActive
                      </span>
                    </Link>
                  )}
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "12%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.createdAt
                      ? moment(e.createdAt).format("DD-MM-YYYY")
                      : "--"}
                  </div>
                </span>
              </td>
              <td
                data-field="Actions"
                data-autohide-disabled="false"
                aria-label="null"
                className="datatable-cell"
                style={{ width: "10%" }}
              >
                <span
                  style={{
                    overflow: "visible",
                    position: "relative",
                  }}
                >
                  <Dropdown>
                    <Dropdown.Toggle style={{ all: "unset" }}>
                      <a
                        href="javascript:void (0)"
                        className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        title="Action"
                      >
                        <span className="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                                fill="#000000"
                              ></path>
                              <path
                                d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                                fill="#000000"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul className="navi flex-column navi-hover py-2">
                        <li className="navi-header font-weight-bolder   text-uppercase font-size-xs text-primary pb-2">
                          Choose an action:
                        </li>
                        <li className="navi-item">
                          <a
                            href="javascript:void(0)"
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.toggleDetail(e);
                            }}
                            className="navi-link"
                          >
                            <span className="navi-text">User Details</span>
                          </a>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>

                  <a
                    href="javascript:void (0)"
                    onClick={() => this.props.toggle(e._id)}
                    className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                    title="Delete"
                  >
                    <span className="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
        <ModalPopup
          show={this.props.modal}
          onHide={this.props.toggle}
          delete_id={this.props.delete_id}
          deleted={this.props.deleted}
        />
        <StatusModal
          show={this.props.modalActive}
          msg={this.props.msg}
          onHide={this.props.toggleAcitve}
          status_id={this.props.status_id}
          Update={this.props.Update}
        />
        <UserDatail
          detailModal={this.props.detailModal}
          toggleDetail={this.props.toggleDetail}
          detail={this.props.detail}
        />
      </>
    );
  }
}
export default UserGrid;
