import React, { Component } from "react";

class UserDatail extends Component {
  render() {
    const detail = this.props.detail;
    return (
      <>
        <div
          className={
            this.props.detailModal
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">User Details</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleDetail}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Name:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.firstName + " " + detail.lastName}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Email:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.email}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Cell number with country code:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.mobile ? detail.mobile : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              What you do:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.profession ? detail.profession : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Where you’re from:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.location ? detail.location : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Passions:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.passions && detail.passions.length > 0
                ? detail.passions && detail.passions.map((e) => e).join(", ")
                : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Bio:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.bio ? detail.bio : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              What are you hoping to gain from this community?{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.hope ? detail.hope : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              What can you contribute to this community?{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.contribute ? detail.contribute : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Social Media:{" "}
            </a>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              LinkedIn{" "}
            </a>
            <a
              href={
                detail.socialMediaLinks && detail.socialMediaLinks.instagram
                  ? detail.socialMediaLinks.instagram
                  : "javascript:void(0)"
              }
              target="_blank"
              className="text-muted font-weight-bold education-margin"
            >
              {detail.socialMediaLinks
                ? detail.socialMediaLinks.instagram
                : "--"}
            </a>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Facebook{" "}
            </a>
            <a
              href={
                detail.socialMediaLinks && detail.socialMediaLinks.facebook
                  ? detail.socialMediaLinks.facebook
                  : "javascript:void(0)"
              }
              target="_blank"
              className="text-muted font-weight-bold education-margin"
            >
              {detail.socialMediaLinks
                ? detail.socialMediaLinks.facebook
                : "--"}
            </a>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Twitter{" "}
            </a>
            <a
              href={
                detail.socialMediaLinks && detail.socialMediaLinks.twitter
                  ? detail.socialMediaLinks.twitter
                  : "javascript:void(0)"
              }
              target="_blank"
              className="text-muted font-weight-bold education-margin"
            >
              {detail.socialMediaLinks ? detail.socialMediaLinks.twitter : "--"}
            </a>
          </div>
        </div>
        {this.props.detailModal ? (
          <div
            className="offcanvas-overlay"
            onClick={this.props.toggleDetail}
          ></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default UserDatail;
