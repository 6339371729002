import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SideBar from "../common/sideBar";
import ScrollTop from "../common/scrollTop";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import AdministratorEmpty from "../administrator/adminEmpty";
import SortingIcon from "../common/sortingIcon";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { deleted, activated, deactivated, failed } from "../common/misc";
import SortingIconUp from "../common/sortingIconUp";
import {
  deleteEvent,
  getEvents,
  loadEvents,
  updateEventFeatured,
  updateEventStatus,
} from "../../store/events";
import EventsGrid from "./eventsGrid";
import UserProfile from "../common/userProfile";
import Search from "./search";
import AddEvent from "./addEvent";

class Events extends Component {
  state = {
    userShow: false,
    isShow: false,
    modal: false,
    active: false,
    id: "",
    event_id: "",
    status: "",
    msg: "",
    data: null,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editShow: false,
    delete_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    sort: "createdAt",
    order: "desc",
    detailModal: false,
    detail: {},
    keyword: "",
    edit: false,
    featured: false,
  };

  sortBy = (sort) => {
    if (this.state.order === "desc")
      this.setState(
        {
          order: "asc",
          sort,
        },
        () =>
          this.props.loadEvents(
            {
              order: this.state.order,
              sort: this.state.sort,
              page: this.state.currentPage,
              keyword: this.state.keyword,
            },
            this.sortCallback
          )
      );
    else {
      this.setState(
        {
          order: "desc",
          sort,
        },
        () =>
          this.props.loadEvents(
            {
              order: this.state.order,
              sort: this.state.sort,
              page: this.state.currentPage,
              keyword: this.state.keyword,
            },
            this.sortCallback
          )
      );
    }
  };

  sortCallback = (res) => {
    if (res.status !== 200) {
      toast(<AlertError message={res.data.message} />);
    }
  };

  toggleDetail = (detail) => {
    this.setState({ detailModal: !this.state.detailModal, detail });
  };

  toggleAddEvent = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      event_id: id,
      status: status,
      msg: msg,
      featured: false,
    });
  };
  toggleFeatured = (id, msg) => {
    this.setState({
      featured: true,
      active: !this.state.active,
      event_id: id,
      msg: msg,
    });
  };
  toggleEditEvent = (data, id) => {
    this.setState({
      isShow: !this.state.isShow,
      edit: !this.state.edit,
      data: data,
      id: id,
    });
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  UpdateStatus = (id) => {
    const formData = {
      status: this.state.status,
    };
    if (this.state.featured) {
      this.props.updateEventFeatured(
        this.state.event_id,
        this.callbackFeatured
      );
    } else {
      this.props.updateEventStatus(
        this.state.event_id,
        formData,
        this.callbackActive
      );
    }
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadEvents({
        order: this.state.order,
        sort: this.state.sort,
        page: this.state.currentPage,
        keyword: this.state.keyword,
      });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === "accepted") {
        toast(<AlertSuccess message={"Event accepted"} />);
      } else {
        toast(<AlertSuccess message={"Event Rejected"} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  callbackFeatured = (res) => {
    if (res && res.status === 200) {
      this.props.loadEvents({
        order: this.state.order,
        sort: this.state.sort,
        page: this.state.currentPage,
        keyword: this.state.keyword,
      });
      this.setState({
        active: !this.state.active,
      });
      toast(<AlertSuccess message={"Marked as featured"} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  deletedEvent = (id) => {
    this.props.deleteEvent(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadEvents({
        page: this.state.currentPage,
        order: this.state.order,
        sort: this.state.sort,
        keyword: this.state.keyword,
      });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadEvents({
        order: this.state.order,
        sort: this.state.sort,
        page: this.state.currentPage,
      });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadEvents({
        page: data.page,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };

  changeKeyword = (keyword) => {
    this.setState({ keyword });
  };

  onSearch = (e) => {
    e.preventDefault();
    if (this.state.keyword) {
      this.props.loadEvents({
        keyword: this.state.keyword,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };

  reset = () => {
    this.props.loadEvents({
      page: this.state.currentPage,
      order: this.state.order,
      sort: this.state.sort,
    });
    this.setState({ keyword: "" });
  };

  render() {
    const events = this.props.events;
    const loading = this.props.users;
    const {
      record_per_page,
      total_record,
      total_page,
      current_page,
      total_record_on_current_page,
    } = this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Events
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold mr-6">
                          Total : {total_record} Records
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          changeKeyword={this.changeKeyword}
                          reset={this.reset}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddEvent();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Event
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Event Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/add/edit/delete/activate/deactivate
                              Events.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span onClick={() => this.sortBy("title")}>
                                    Title
                                    {this.state.sort === "title" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "12%" }}
                                >
                                  <span>Created By</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "13%" }}
                                >
                                  <span
                                    onClick={() => this.sortBy("startDate")}
                                  >
                                    Start Date
                                    {this.state.sort === "startDate" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span onClick={() => this.sortBy("endDate")}>
                                    End Date
                                    {this.state.sort === "endDate" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>Status</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "8%" }}
                                >
                                  <span>Featured</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span
                                    onClick={() => this.sortBy("createdAt")}
                                  >
                                    Created On
                                    {this.state.sort === "createdAt" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>Action</span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && events.length === 0 ? (
                              <AdministratorEmpty />
                            ) : (
                              <EventsGrid
                                events={this.props.events}
                                modalActive={this.state.active}
                                toggleActive={this.toggleAcitve}
                                status_id={this.state.event_id}
                                Update={this.UpdateStatus}
                                msg={this.state.msg}
                                detail={this.state.detail}
                                detailModal={this.state.detailModal}
                                toggleDetail={this.toggleDetail}
                                deleted={this.deletedEvent}
                                toggle={this.toggle}
                                modal={this.state.modal}
                                delete_id={this.state.delete_id}
                                toggleEditEvent={this.toggleEditEvent}
                                toggleFeatured={this.toggleFeatured}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddEvent
            initialPage={this.state.currentPage}
            isShow={this.state.isShow}
            toggleAddEvent={this.toggleAddEvent}
            editData={this.state.data}
            edit={this.state.edit}
            id={this.state.id}
            toggleEditEvent={this.toggleEditEvent}
            setEditDataNull={() => this.setState({ data: null })}
            sort={this.state.sort}
            order={this.state.order}
            keyword={this.state.keyword}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  events: getEvents(state).events,
  pagination: getEvents(state).pagination,
  loading: getEvents(state).loading,
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (data) => dispatch(loadEvents(data)),
  updateEventStatus: (id, data, callback) =>
    dispatch(updateEventStatus(id, data, callback)),
  deleteEvent: (id, callback) => dispatch(deleteEvent(id, callback)),
  updateEventFeatured: (id, callback) =>
    dispatch(updateEventFeatured(id, callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
