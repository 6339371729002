import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import StatusModal from "../../common/statusModal";
import ModalPopup from "../../common/modal";
import Updatestatus from "./updatestatus";

class DonorGrid extends Component {
  render() {
    return (
      <>
        <tbody className="datatable-body">
          {this.props.donor.map((e, i) => (
            <tr
              data-row="10"
              className="datatable-row"
              style={{ left: "0px" }}
              key={i}
            >
              <td className="datatable-cell" style={{ width: "15%" }}>
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <div className="ml-4">
                      <div className="text-dark-75 font-weight-bolder font-size-lg mb-0">
                        {e.name ? e.name : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="datatable-cell" style={{ width: "20%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.email ? e.email : "--"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.phone ? e.phone : "--"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.amount ? `$${e.amount}` : "--"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                {" "}
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.status === "pending" ? (
                      <span
                        className="label label-lg font-weight-bold  label-light-danger label-inline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.toggleStatusUpdate(e._id);
                        }}
                      >
                        Pending
                      </span>
                    ) : e.status === "paid" ? (
                      <span
                        className="label label-lg font-weight-bold  label-light-success label-inline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.toggleStatusUpdate(e._id);
                        }}
                      >
                        Paid
                      </span>
                    ) : (
                      <span
                        className="label label-lg font-weight-bold  label-light-danger label-inline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.toggleStatusUpdate(e._id);
                        }}
                      >
                        Failed
                      </span>
                    )}
                  </div>
                </span>
              </td>
              <td
                className="datatable-cell"
                style={{ width: "20%", wordBreak: "break-all" }}
              >
                <span>
                  <div className="d-dlex flex-column">
                    <div className="font-weight-bolder font-size-lg mb-0">
                      {e.createdAt ? moment(e.createdAt).format("LL") : "--"}
                    </div>
                    <div className="font-weight-bold text-muted">
                      {e.createdAt
                        ? moment(e.createdAt).format("hh:mm A")
                        : "--"}
                    </div>
                  </div>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
        <Updatestatus
          isShow={this.props.isShow}
          toggleStatusUpdate={this.props.toggleStatusUpdate}
          id={this.props.donor_id}
          sort={this.props.sort}
          order={this.props.order}
          currentPage={this.props.currentPage}
          keyword={this.props.keyword}
        />
      </>
    );
  }
}
export default DonorGrid;
