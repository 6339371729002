import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import { searchUser } from "./user";
//import moment from "moment";

const slice = createSlice({
    name: "forums",
    initialState: {
        forumTopic: [],
        pagination: {},
        loading: false,
        lastFetch: null,
        forumCategory: [],
        paginationCat: {}
    },
    reducers: {
        forumTopicRequested: (administrator, action) => {
            administrator.loading = true;
        },

        forumTopicReceived: (administrator, action) => {
            administrator.forumTopic = action.payload.data;
            administrator.pagination = action.payload.pagination;
            administrator.loading = false;
            administrator.lastFetch = Date.now();
        },

        forumTopicRequestFailed: (administrator, action) => {
            administrator.loading = false;
        },
        forumCategoryRequested: (administrator, action) => {
            administrator.loading = true;
        },

        forumCategoryReceived: (administrator, action) => {
            administrator.forumCategory = action.payload.data;
            administrator.paginationCat = action.payload.pagination;
            administrator.loading = false;
            administrator.lastFetch = Date.now();
        },

        forumCategoryRequestFailed: (administrator, action) => {
            administrator.loading = false;
        },
    },
});

export const {
    forumTopicRequested,
    forumTopicReceived,
    forumTopicRequestFailed,
    forumCategoryRequested,
    forumCategoryReceived,
    forumCategoryRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "forumTopics/";


export const searchForumTopic = (params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "search",
            params,
            method: "GET",
            onStart: forumTopicRequested.type,
            onSuccess: forumTopicReceived.type,
            onError: forumTopicRequestFailed.type,
        })
    );
};

export const addTopic = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};

export const getFoumCatergory = (params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories",
            params,
            method: "GET",
            onStart: forumCategoryRequested.type,
            onSuccess: forumCategoryReceived.type,
            onError: forumCategoryRequestFailed.type,
        })
    );
};

export const updateForumTopicStatus = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "changeStatus/" + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const deleteForum = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "DELETE",
            callback,
        })
    );
};

export const uploadImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories/uploadImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const addCategory = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories",
            method: "POST",
            data,
            callback,
        })
    );
};

export const editCategory = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories/" + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const deleteCategory = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories/" + id,
            method: "DELETE",
            callback,
        })
    );
};

export const uploadGridImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadGridImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const uploadCoverImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadCoverImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const editForumTopic = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const getForum = createSelector(
    (state) => state.entities.forums,
    (forums) => forums
);
