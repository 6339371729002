import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { Link } from "react-router-dom";
import {
  addEvent,
  editEvent,
  loadEvents,
  uploadCoverImage,
} from "../../../store/events";
import {
  addCategory,
  editCategory,
  getFoumCatergory,
  uploadImage,
} from "../../../store/forum";
import close from "../../../include/media/close.svg";

const initialState = {
  loading: false,
  active: false,
  image: {},
  imageName: "",
  data: {
    title: "",
  },
  errors: {},
  browserLabel: "Browse",
  disable: false,
};
class AddCategory extends Form {
  state = initialState;

  schema = {
    title: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Event Title is required.";
              break;
          }
        });
        return errors;
      }),
  };

  uploadFile = async (e) => {
    this.setState({
      loading: true,
      browserLabel: "Uploading...",
      disabled: true,
    });
    this.setState({ imageName: e.target.files[0].name });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ image: res.data.data });
      console.log("img", this.state.image);
      delete errors.image;
      this.setState({
        loading: false,
        errors,
        browserLabel: "Browse",
        disable: false,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.edit == false && this.props.edit !== prevProps.edit) {
      this.setState({
        loading: false,
        active: false,
        image: {},
        imageName: "",
        data: {
          title: "",
        },
        errors: {},
        browserLabel: "Browse",
        disable: false,
      });
      this.props.setEditDataNull();
    }
    if (
      this.props.edit == true &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = { ...this.state.data };
      let image = {};
      let active;
      image = this.props.editData && this.props.editData.image;
      data.title = this.props.editData && this.props.editData.name;
      active = this.props.editData && this.props.editData.isActive;

      this.setState({ data, image, active });
    }
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    const { errors } = this.state;
    const data = { ...this.state.data };
    if (_.isEmpty(this.state.image)) {
      errors.image = "Image is required";
    }
    this.setState({ errors, loading: false });
    let payload = {
      name: data.title,
      image: this.state.image,
      isActive: this.state.active,
    };
    if (_.isEmpty(this.state.errors)) {
      if (this.props.edit == true) {
        this.props.editCategory(
          this.props.editData && this.props.editData._id,
          payload,
          (res) => {
            this.setState({ loading: false });
            if (res && res.status === 200) {
              this.props.getFoumCatergory({
                sort: this.props.sort,
                order: this.props.order,
                page: 1,
                keyword: this.props.keyword,
              });
              this.toggleAddClose();
              return toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              return toast(
                <AlertError message={res && res.data && res.data.message} />
              );
            }
          }
        );
      } else {
        this.props.addCategory(payload, (res) => {
          this.setState({ loading: false });
          if (res && res.status === 200) {
            this.props.getFoumCatergory({
              sort: this.props.sort,
              order: this.props.order,
              page: 1,
              keyword: this.props.keyword,
            });
            this.toggleAddClose();
            return toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            return toast(
              <AlertError message={res && res.data && res.data.message} />
            );
          }
        });
      }
    }
  };

  toggleAddClose = () => {
    this.setState(initialState);
    if (!this.props.edit) {
      this.props.toggleAddCategory();
    } else {
      this.props.toggleEditCategory();
    }
  };

  toggleActive = () => {
    this.setState({
      active: !this.state.active,
    });
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Category</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => this.toggleAddClose()}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("title", "Name")}
            <div class="form-group">
              <label for="formFile" class="form-label">
                Select Image
              </label>
              <div class="upload-btn-wrapper d-flex justify-content-end">
                <button class="btn">{this.state.browserLabel}</button>
                <input type="file" name="myfile" onChange={this.uploadFile} />
              </div>
              {this.state.errors && this.state.errors.image && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.image}
                  </div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.image) && (
              <div class="form-group" style={{ width: "50%" }}>
                <div class="ui-box" style={{ position: "relative" }}>
                  <div class="ui-img">
                    <img
                      src={process.env.REACT_APP_CDN + this.state.image.small}
                      b
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => this.setState({ image: {} })}
                    class="close-img"
                    style={{
                      position: "absolute",
                      top: "-6%",
                      right: "-6%",
                      background: "#fff",
                      borderRadius: "50%",
                      height: "22px",
                      width: "22px",
                      verticalAlign: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <label>Active</label>
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.active}
                    onClick={this.toggleActive}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (data, callback) => dispatch(uploadImage(data, callback)),
  addCategory: (data, callBack) => dispatch(addCategory(data, callBack)),
  editCategory: (id, data, callBack) =>
    dispatch(editCategory(id, data, callBack)),
  getFoumCatergory: (data, callback) =>
    dispatch(getFoumCatergory(data, callback)),
});
export default connect(null, mapDispatchToProps)(AddCategory);
