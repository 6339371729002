import moment from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

class TimeInput extends Component {
  state = {
    formclass: "form-group custom-input show on",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    //this.initDatepicker();
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const { name, label, error, disabled, minTime, ...rest } = this.props;
    return (
      <>
        <div className={this.state.formclass}>
          <label htmlFor={label}>{label}</label>
          <DatePicker
            autoComplete="off"
            name={name}
            id={label}
            className={
              error
                ? "form-control datepicker error"
                : "form-control datepicker"
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            showTimeSelect
            minTime={
              minTime ? new Date() : setHours(setMinutes(new Date(), 0), 0)
            }
            maxTime={setHours(setMinutes(new Date(), 45), 23)}
            timeFormat="hh:mm aa"
            timeIntervals={15}
            showTimeSelectOnly
            timeCaption="Time"
            dateFormat="h:mm a"
            {...rest}
          />
          {error && (
            <div className="fv-plugins-message-container">
              <div data-validator="notEmpty" className="fv-help-block">
                {error}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default TimeInput;
