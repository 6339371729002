import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import AddAdministrator from "../administrator/addAdministrator";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import AdministratorGrid from "./administratorGrid";
import AdministratorEmpty from "./adminEmpty";
import SortingIcon from "../common/sortingIcon";
import {
  getAdministrator,
  loadAllAdministrator,
  deletedAdministrator,
  updateAdministrator,
  updateActiveStatus,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { deleted, activated, deactivated, failed } from "../common/misc";
import SortingIconUp from "../common/sortingIconUp";

class AdminTable extends Component {
  state = {
    userShow: false,
    isShow: false,
    modal: false,
    active: false,
    id: "",
    admin_id: "",
    status: "",
    msg: "",
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editShow: false,
    delete_id: "",
    changePass: "false",
    change_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    sort: "date",
    order: "desc",
  };

  sortBy = (sort) => {
    if (this.state.order === "desc")
      this.setState(
        {
          order: "asc",
          sort,
        },
        () =>
          this.props.loadAllAdministrator(
            {
              page: this.state.currentPage,
              order: this.state.order,
              sort: this.state.sort,
            },
            this.sortCallback
          )
      );
    else {
      this.setState(
        {
          order: "desc",
          sort,
        },
        () =>
          this.props.loadAllAdministrator(
            {
              page: this.state.currentPage,
              order: this.state.order,
              sort: this.state.sort,
            },
            this.sortCallback
          )
      );
    }
  };

  sortCallback = (res) => {
    if (res.status !== 200) {
      toast(<AlertError message={res.data.message} />);
    }
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      admin_id: id,
      status: status,
      msg: msg,
    });
  };
  toggleEditUser = (data, id) => {
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      id: id,
    });
  };
  toggleChangePassword = (id) => {
    this.setState({
      changePass: !this.state.changePass,
      change_id: id,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  UpdateAdmin = (id) => {
    const formData = {
      id,
      isActive: this.state.status,
    };
    this.props.updateActiveStatus(formData, this.callbackActive);
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({ page: this.state.currentPage });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === true) {
        toast(<AlertSuccess message={activated} />);
      } else {
        toast(<AlertSuccess message={deactivated} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  deletedAdministrator = (id) => {
    this.props.deletedAdministrator(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({ page: this.state.currentPage });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllAdministrator({
        page: this.state.currentPage,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllAdministrator({
        page: data.page,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };
  render() {
    const { administrator, loading } = this.props.administrator;
    const { role } = JSON.parse(localStorage.getItem("admin_details"));
    const {
      record_per_page,
      total_record,
      total_page,
      current_page,
      total_record_on_current_page,
    } = this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Administrators
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          Total : {total_record} Records
                        </span>
                      </div>
                    </div>
                    {role === "masteradmin" && (
                      <div className="d-flex align-items-center">
                        <a
                          href="#/"
                          className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleAddUser();
                          }}
                          data-toggle="tooltip"
                          data-placement="right"
                          data-container="body"
                          data-boundary="window"
                        >
                          Add User
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Administrators Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/add/edit/delete/activate/deactivate
                              administrators.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span onClick={() => this.sortBy("name")}>
                                    First Name
                                    {this.state.sort === "name" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span onClick={() => this.sortBy("name")}>
                                    Last Name
                                    {this.state.sort === "name" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "25%" }}
                                >
                                  <span onClick={() => this.sortBy("email")}>
                                    Email
                                    {this.state.sort === "email" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span>Master Admin</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span>
                                    Status
                                    {/* {this.state.sort_by === "status" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortBystatus}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortBystatus}
                                      />
                                    )} */}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span>Action</span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && administrator.length === 0 ? (
                              <AdministratorEmpty />
                            ) : (
                              <AdministratorGrid
                                changePass={this.state.changePass}
                                toggleChangePassword={this.toggleChangePassword}
                                change_id={this.state.change_id}
                                delete_id={this.state.delete_id}
                                initialPage={this.state.current_page}
                                editShow={this.state.editShow}
                                toggleEditUser={this.toggleEditUser}
                                data={this.state.data}
                                msg={this.state.msg}
                                status_id={this.state.admin_id}
                                Update={this.UpdateAdmin}
                                modalActive={this.state.active}
                                toggleAcitve={this.toggleAcitve}
                                administrator={administrator}
                                modal={this.state.modal}
                                toggle={this.toggle}
                                id={this.state.id}
                                deleted={this.deletedAdministrator}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddAdministrator
            initialPage={this.state.current_page}
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state),
  pagination: getAdministrator(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params, callback) =>
    dispatch(loadAllAdministrator(params, callback)),
  deletedAdministrator: (id, callback) =>
    dispatch(deletedAdministrator(id, callback)),
  updateAdministrator: (id, data, callbackActive) =>
    dispatch(updateAdministrator(id, data, callbackActive)),
  updateActiveStatus: (data, callback) =>
    dispatch(updateActiveStatus(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminTable)
);
