export const mobileRequired = "Mobile Number field is required";

export const mobileInvalid = "Mobile Number field is invalid";
export const dobRequired = "Date of birth field is required";
export const dobInvalid =
  "Age should be more than 18 years in order to proceed";

export const firstNameRequired = "First Name field is required";
export const lastNameRequired = "Last Name field is required";
export const categoryRequired = "Category Name field is required";
export const emailRequired = "Email field is required";
export const emailInvalid = "Email field is invalid";
export const sectionRequired = "Section Name is required";
export const questionRequired = "Question Group Name is required";
export const passwordRequired = "Password field is required";
export const passwordInvalid =
  "Password length must be at least 8 characters long";
export const passwordInvalidMax =
  "Password length cannot be more than 25 characters long.";

export const accountNotExist = "Sorry, this account does not exist.";
export const emailExist =
  "A user with this email or mobile number already exists. Please login to access the dashboard.";

export const informationSaved = "Information saved.";
export const deleted = "Deleted successfully.";
export const activated = "Activated successfully.";
export const deactivated = "Deactivated successfully.";
export const failed = "Something went wrong.";

export const published = "Published successfully";
export const unpublished = "Unpublished successfully";

export const title = "Title field is required";
export const description = "Description field is required";
export const code = "Code field is required";
export const minRange = "Min Range field is required";
export const maxRange = "Max Range field is required";
export const severityRequired = "Severity field is required";
export const recommendationRequired = "Recommendation field is required";

export const pageKey = "Page Key field is required";
export const KeyRequired = "Key field is required";
export const messageRequired = "Message field is required";

export const category = "Category field is required";
export const photo = "Featured Image fields is required";
export const blog = "Blog field is required";
export const publishDate = "Publish Date field is required";
export const author = "Author field is required";
export const question = "Question field is required";
export const answer = "Answer field is required";
