import React, { Component } from "react";
import SearchIcon from "../common/searchIcon";

class Search extends Component {
  render() {
    return (
      <>
        <form onSubmit={this.props.onSearch}>
          <div
            className="input-group input-group-sm input-group-solid"
            onClick={this.props.onSearch}
            style={{ maxWidth: "175px" }}
          >
            <input
              name="name"
              id="name"
              className="form-control"
              placeholder="Search By Title"
              autoComplete="off"
              dir="ltr"
              value={this.props.keyword}
              onChange={(e) => {
                this.props.changeKeyword(e.target.value);
              }}
            />
            <SearchIcon />
          </div>
        </form>
        <form onSubmit={this.props.reset}>
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "75px" }}
            onClick={this.props.reset}
          >
            <a href="javascript:void (0)" type="submit">
              <span
                className="text-dark-50 font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                Reset
              </span>
            </a>
          </div>
        </form>
      </>
    );
  }
}

export default Search;
