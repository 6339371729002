import React, { Component } from "react";

class Textarea extends Component {

  render() {
    const { name, label, error, ...rest } = this.props;
    return (
      <div className="form-group row fv-plugins-icon-container has-danger">
        <div className="col-lg-12">
          <label htmlFor={name}>{label}</label>
          <textarea
            name={name}
            id={name}
            className="form-control"
            {...rest}
          />
          {error && <div className="fv-plugins-message-container">
              <div data-validator="notEmpty" className="fv-help-block">{error}</div>
          </div>}
        </div>
      </div>
    );
  }
}

export default Textarea;
