import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "users",
    initialState: {
        profile: {},
        loading: false,
        users: [],
        pagination: {}
    },
    reducers: {
        profileRequested: (misc, action) => {
            misc.loading = true;
        },
        profileReceived: (misc, action) => {
            misc.profile = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        profileRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        userRequested: (misc, action) => {
            misc.loading = true;
        },
        userReceived: (misc, action) => {
            misc.users = action.payload.data;
            misc.pagination = action.payload.pagination;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        userRequestFailed: (misc, action) => {
            misc.loading = false;
        },
    },
});

export const {
    profileRequestFailed,
    profileReceived,
    profileRequested,
    userRequested,
    userReceived,
    userRequestFailed

} = slice.actions;
export default slice.reducer;

// Action Creators
const userUrl = "users/";
const authUrl = "auth/"

export const signIn = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: authUrl + "admins",
            method: "POST",
            data,
            callback,
        })
    );
};

export const searchUser = (params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: userUrl + "search",
            params,
            method: "GET",
            onStart: userRequested.type,
            onSuccess: userReceived.type,
            onError: userRequestFailed.type,
        })
    );
};

export const updateActiveStatus = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: userUrl + "updateStatus",
            method: "PUT",
            data,
            callback,
        })
    );
};

export const deleteUser = (id, callback) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: userUrl + id,
            method: "DELETE",
            callback,
        })
    );
};

export const getUser = createSelector(
    (state) => state.entities.users,
    (users) => users
);
