import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
    name: "basic",
    initialState: {
        basic: {},
        loading: false,
        lastFetch: null,
    },
    reducers: {
        basicRequested: (admin, action) => {
            admin.loading = true;
        },
        basicReceived: (admin, action) => {
            admin.basic = action.payload.data;
            admin.loading = false;
            admin.lastFetch = Date.now();
        },
        basicRequestFailed: (admin, action) => {
            admin.loading = false;
        },
    },
});

export const {
    basicRequested,
    basicReceived,
    basicRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "settings/";


export const getBasics = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url,
            onStart: basicRequested.type,
            onSuccess: basicReceived.type,
            onError: basicRequestFailed.type,
        })
    );
};

export const addEditBasics = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url,
            method: "POST",
            data,
            callback,
        })
    );
};


export const getBasic = createSelector(
    (state) => state.entities.basic,
    (basic) => basic
);