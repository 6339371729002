import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import close from "../../include/media/close.svg";
import {
  loadAllAdministrator,
  addAdministrator,
  getAdministrator,
} from "../../store/administrator";

import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  informationSaved,
  failed,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";
import { addEditBasics, getBasic, getBasics } from "../../store/basic";

const initialState = {
  loading: false,
  data: {
    serverUrl: "",
    emailFrom: "",
    adminEmails: "",
    facebook: "",
    youtube: "",
    instagram: "",
    twitter: "",
  },
  errors: {},
};
class AddAdministrator extends Form {
  state = initialState;
  schema = {
    twitter: Joi.string().allow(null, ""),
    facebook: Joi.string().allow(null, ""),
    instagram: Joi.string().allow(null, ""),
    youtube: Joi.string().allow(null, ""),
    serverUrl: Joi.string()
      .required()
      .label("Server URL")
      .error(() => {
        return { message: "Server Url is required field" };
      }),
    emailFrom: Joi.string()
      .required()
      .label("Email From")
      .error(() => {
        return { message: "Email From is required field" };
      }),
    adminEmails: Joi.string()
      .required()
      .label("Admin Emails")
      .error(() => {
        return { message: "Admin Email is required field" };
      }),
  };

  doSubmit = () => {
    const {
      adminEmails,
      emailFrom,
      serverUrl,
      youtube,
      instagram,
      facebook,
      twitter,
    } = this.state.data;
    const { master } = this.state;
    var formdata = {
      serverURL: serverUrl,
      emailFrom,
      adminEmails,
      mediaURLs: { youtube, instagram, facebook, twitter },
    };
    this.props.addEditBasics(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.getBasics();
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  toggleAddClose = () => {
    this.setState(initialState);
    this.props.toggleAddUser();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isShow && this.props.isShow !== prevProps.isShow) {
      this.props.getBasics();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      const data = { ...this.state.data };
      const basic = this.props.basic;
      data.serverUrl = basic && basic.serverURL;
      data.adminEmails = basic && basic.adminEmails;
      data.emailFrom = basic && basic.emailFrom;
      data.facebook = basic && basic.mediaURLs && basic.mediaURLs.facebook;
      data.youtube = basic && basic.mediaURLs && basic.mediaURLs.youtube;
      data.instagram = basic && basic.mediaURLs && basic.mediaURLs.instagram;
      data.twitter = basic && basic.mediaURLs && basic.mediaURLs.twitter;
      this.setState({ data });
    }
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Basic settings</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("serverUrl", "Server URL")}
            {this.renderInput("emailFrom", "Email From")}
            {this.renderInput("adminEmails", "Admin Email")}
            {this.renderInput("facebook", "Facebook")}
            {this.renderInput("youtube", "Youtube")}
            {this.renderInput("instagram", "Instagram")}
            {/* {this.renderInput("twitter", "Twitter")} */}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: getBasic(state).loading,
  basic: getBasic(state).basic,
});
const mapDispatchToProps = (dispatch) => ({
  getBasics: () => dispatch(getBasics()),
  addEditBasics: (data, callback) => dispatch(addEditBasics(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAdministrator);
