import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import close from "../../include/media/close.svg";
import {
  emailRequired,
  lastNameRequired,
  firstNameRequired,
  informationSaved,
  failed,
} from "../common/misc";
import {
  loadAllAdministrator,
  updateAdministrator,
  getAdministrator,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState = {
  data: {
    first_name: "",
    last_name: "",
    email: "",
  },
  errors: {
    first_name: "",
    last_name: "",
    email: "",
  },
};

class EditAdministrator extends Form {
  state = initialState;
  schema = {
    first_name: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),
    last_name: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
  };
  doSubmit = () => {
    const { first_name, last_name, email } = this.state.data;
    const id = this.props.id;
    var formData = {
      firstName: first_name,
      lastName: last_name,
      email: email,
    };
    this.props.updateAdministrator(id, formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({ page: this.props.initialPage });
      this.props.toggleEditUser();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  componentDidMount = () => {
    this.setState({
      data: {
        first_name: this.props.data.firstName,
        last_name: this.props.data.lastName,
        email: this.props.data.email,
      },
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        data: {
          first_name: nextProps.data.firstName,
          last_name: nextProps.data.lastName,
          email: nextProps.data.email,
        },
      });
    }
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.editShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit Administrator Detail</h3>
            <Link
              to={"#"}
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </Link>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("first_name", "First Name")}
            {this.renderInput("last_name", "Last Name")}
            {this.renderInput("email", "Email")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.editShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state).administrator,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  updateAdministrator: (id, data, callback) =>
    dispatch(updateAdministrator(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdministrator);
