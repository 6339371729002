import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalPopup from "../common/modal";
import StatusModal from "../common/statusModal";
import EditAdministrator from "./editAdministrator";
import ChangePassword from "./changePassword";
import { getAdminId, getAdminRole } from "../services/localStorageServices";

class AdministratorGrid extends Component {
  render() {
    console.log("adm", this.props.administrator);
    const { _id, role } = JSON.parse(localStorage.getItem("admin_details"));
    return (
      <>
        <tbody className="datatable-body">
          {this.props.administrator.map((e, i) => (
            <tr
              data-row="10"
              className="datatable-row"
              style={{ left: "0px" }}
              key={i}
            >
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.firstName}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.lastName}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "25%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.email}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.role === "masteradmin" ? "Yes" : "No"}
                  </div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  {role === "masteradmin" ? (
                    <>
                      {_id === e._id ? (
                        <Link to={"#"} style={{ cursor: "not-allowed" }}>
                          <span className="label label-lg font-weight-bold  label-light-success label-inline">
                            Active
                          </span>
                        </Link>
                      ) : e.isActive === true ? (
                        <Link
                          to={"#"}
                          onClick={() => {
                            this.props.toggleAcitve(
                              e._id,
                              false,
                              "Are you sure you want to deactivate this record?"
                            );
                          }}
                        >
                          <span className="label label-lg font-weight-bold  label-light-success label-inline">
                            Active
                          </span>
                        </Link>
                      ) : (
                        <Link
                          to={"#"}
                          onClick={() => {
                            this.props.toggleAcitve(
                              e._id,
                              true,
                              "Are you sure you want to activate this record?"
                            );
                          }}
                        >
                          <span className="label label-lg font-weight-bold  label-light-danger label-inline">
                            InActive
                          </span>
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {e.isActive === true ? (
                        <Link to={"#"} style={{ cursor: "not-allowed" }}>
                          <span className="label label-lg font-weight-bold label-light-success label-inline">
                            Active
                          </span>
                        </Link>
                      ) : (
                        <Link to={"#"} style={{ cursor: "not-allowed" }}>
                          <span className="label label-lg font-weight-bold  label-light-danger label-inline">
                            InActive
                          </span>
                        </Link>
                      )}
                    </>
                  )}
                </span>
              </td>
              <td
                data-field="Actions"
                data-autohide-disabled="false"
                aria-label="null"
                className="datatable-cell"
                style={{ width: "20%" }}
              >
                <span style={{ overflow: "visible", position: "relative" }}>
                  {role === "masteradmin" ? (
                    <>
                      <Link
                        to={"#"}
                        onClick={() => {
                          this.props.toggleEditUser(e, e._id);
                        }}
                        className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        title="Edit details"
                      >
                        <span className="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                              ></path>
                              <path
                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </Link>
                      <Link
                        to={"#"}
                        onClick={() => {
                          this.props.toggleChangePassword(e._id);
                        }}
                        className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        title="Change Password"
                      >
                        <span className="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <g />
                              <path
                                d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                        </span>
                      </Link>
                      {_id === e._id ? (
                        ""
                      ) : (
                        <Link
                          to={"#"}
                          onClick={() => this.props.toggle(e._id)}
                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          title="Delete"
                        >
                          <span className="svg-icon svg-icon-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {_id === e._id && (
                        <>
                          <Link
                            to={"#"}
                            onClick={() => {
                              this.props.toggleEditUser(e, e._id);
                            }}
                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            title="Edit details"
                          >
                            <span className="svg-icon svg-icon-md">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </Link>
                          <Link
                            to={"#"}
                            onClick={() => {
                              this.props.toggleChangePassword(e._id);
                            }}
                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            title="Change Password"
                          >
                            <span className="svg-icon svg-icon-md">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <g />
                                  <path
                                    d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                                    fill="#000000"
                                  />
                                </g>
                              </svg>
                            </span>
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
        <ModalPopup
          show={this.props.modal}
          onHide={this.props.toggle}
          delete_id={this.props.delete_id}
          deleted={this.props.deleted}
        />
        <StatusModal
          show={this.props.modalActive}
          msg={this.props.msg}
          onHide={this.props.toggleAcitve}
          status_id={this.props.status_id}
          Update={this.props.Update}
        />
        <EditAdministrator
          initialPage={this.props.initialPage}
          editShow={this.props.editShow}
          toggleEditUser={this.props.toggleEditUser}
          data={this.props.data}
          id={this.props.id}
        />
        <ChangePassword
          initialPage={this.props.initialPage}
          changePass={this.props.changePass}
          toggleChangePassword={this.props.toggleChangePassword}
          change_id={this.props.change_id}
        />
      </>
    );
  }
}
export default AdministratorGrid;
