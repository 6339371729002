import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

class TopicDetail extends Component {
  render() {
    const detail = this.props.detail;
    console.log("detail", detail);
    return (
      <>
        <div
          className={
            this.props.detailModal
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Topic Detail</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleDetail}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg"
            >
              Grid Image:{" "}
            </a>
            <img
              style={{ marginTop: "10px" }}
              width={300}
              height={200}
              src={
                detail.gridImage &&
                process.env.REACT_APP_CDN + detail.gridImage.small
              }
              alt=""
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg"
            >
              Cover Image:{" "}
            </a>
            <img
              style={{ marginTop: "10px" }}
              width={300}
              height={200}
              src={
                detail.coverImage &&
                process.env.REACT_APP_CDN + detail.coverImage.small
              }
              alt=""
            />
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Forum Topic Name:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.name ? detail.name : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Category:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.category ? detail.category.name : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Tags:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.tags && detail.tags.map((tag) => tag).join(", ")}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Short Description:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.shortDescription ? detail.shortDescription : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Long Description:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.longDescription ? detail.longDescription : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Video:{" "}
            </a>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Type:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.video ? _.capitalize(detail.video.type) : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              URL:{" "}
            </a>
            <a
              href={detail.video && detail.video.url}
              target="_blank"
              className="text-muted font-weight-bold education-margin"
            >
              {detail.video ? _.capitalize(detail.video.url) : "--"}
            </a>
          </div>
        </div>
        {this.props.detailModal ? (
          <div
            className="offcanvas-overlay"
            onClick={this.props.toggleDetail}
          ></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default TopicDetail;
