import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./Component/signin/signin";
import "./include/css/pages/login/classic/login-2.css";
import "./include/css/style.bundle.css";
import "./include/css/alert.css";
import "./include/css/dev.css";
import "./include/plugins/global/plugins.bundle.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import AlertClose from "./Component/common/alertClose"
import AdminTable from "./Component/administrator/adminTable"
import UsersTable from "./Component/users/usersTable";
import Events from "./Component/events/events";
import TopicTable from "./Component/forum/forumTopic/topicTable";
import CategoryTable from "./Component/forum/forumCategory/categoryTable";
import Achievement from "./Component/settings/notableAchievement/achievement";
import Donor from "./Component/settings/donation/donor";


const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        hideProgressBar
        closeOnClick
        autoClose={5000}
        toastClassName="alert alert-success alert-white"
        closeButton={<AlertClose />}
      />
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/administrators" component={AdminTable} />
          <Route exact path="/users" component={UsersTable} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/forum-topic" component={TopicTable} />
          <Route exact path="/forum-category" component={CategoryTable} />
          <Route exact path="/notable-achievement" component={Achievement} />
          <Route exact path="/donation" component={Donor} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
