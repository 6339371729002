import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SideBar from "../../common/sideBar";
import ScrollTop from "../../common/scrollTop";
import Pagination from "../../common/Pagination";
import { connect } from "react-redux";
import AdministratorEmpty from "../../administrator/adminEmpty";
import SortingIcon from "../../common/sortingIcon";

import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { deleted, activated, deactivated, failed } from "../../common/misc";
import SortingIconUp from "../../common/sortingIconUp";

import AchievementGrid from "./achievementGrid";
import UserProfile from "../../common/userProfile";

// import AddCategory from "./addCategory";
import Search from "./search";
import {
  addAchievement,
  deleteAchievement,
  editAchievement,
  getAchievement,
  loadAchievements,
} from "../../../store/notableAchievement";
import AddAchievement from "./addAchievement";

class CategoryTable extends Component {
  state = {
    userShow: false,
    isShow: false,
    modal: false,
    active: false,
    id: "",
    forum_id: "",
    status: "",
    msg: "",
    data: null,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editShow: false,
    delete_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    sort: "date",
    order: "desc",
    detailModal: false,
    detail: {},
    keyword: "",
    edit: false,
    updateName: "",
    updateDescription: "",
    updateImage: "",
  };

  sortBy = (sort) => {
    if (this.state.order === "desc")
      this.setState(
        {
          order: "asc",
          sort,
        },
        () =>
          this.props.loadAchievements(
            {
              order: this.state.order,
              sort: this.state.sort,
              page: this.state.currentPage,
              keyword: this.state.keyword,
            },
            this.sortCallback
          )
      );
    else {
      this.setState(
        {
          order: "desc",
          sort,
        },
        () =>
          this.props.loadAchievements(
            {
              order: this.state.order,
              sort: this.state.sort,
              page: this.state.currentPage,
              keyword: this.state.keyword,
            },
            this.sortCallback
          )
      );
    }
  };

  sortCallback = (res) => {
    if (res.status !== 200) {
      toast(<AlertError message={res.data.message} />);
    }
  };

  toggleDetail = (detail) => {
    this.setState({ detailModal: !this.state.detailModal, detail });
  };

  toggleAddAchievement = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAcitve = (id, name, description, image, status, msg) => {
    this.setState({
      active: !this.state.active,
      forum_id: id,
      status: status,
      msg: msg,
      updateName: name,
      updateDescription: description,
      updateImage: image,
    });
  };
  toggleEditAchievement = (data, id) => {
    this.setState({
      isShow: !this.state.isShow,
      edit: !this.state.edit,
      data: data,
      id: id,
    });
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  UpdateStatus = () => {
    const formData = {
      name: this.state.updateName,
      isActive: this.state.status,
      description: this.state.updateDescription,
      image: this.state.updateImage,
    };
    this.props.editAchievement(
      this.state.forum_id,
      formData,
      this.callbackActive
    );
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAchievements({
        order: this.state.order,
        sort: this.state.sort,
        page: this.state.currentPage,
        keyword: this.state.keyword,
      });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status) {
        toast(<AlertSuccess message={activated} />);
      } else {
        toast(<AlertSuccess message={deactivated} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  deletedAchievement = (id) => {
    this.props.deleteAchievement(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAchievements({
        page: this.state.currentPage,
        order: this.state.order,
        sort: this.state.sort,
        keyword: this.state.keyword,
      });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAchievements({
        order: this.state.order,
        sort: this.state.sort,
        page: this.state.currentPage,
      });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAchievements({
        page: data.page,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };

  changeKeyword = (keyword) => {
    this.setState({ keyword });
  };

  onSearch = (e) => {
    e.preventDefault();
    if (this.state.keyword) {
      this.props.loadAchievements({
        keyword: this.state.keyword,
        page: this.state.currentPage,
        order: this.state.order,
        sort: this.state.sort,
      });
    }
  };

  reset = () => {
    this.props.loadAchievements({
      page: this.state.currentPage,
      order: this.state.order,
      sort: this.state.sort,
    });
    this.setState({ keyword: "" });
  };

  render() {
    const achievement = this.props.achievement;
    const loading = this.props.users;
    const {
      record_per_page,
      total_record,
      total_page,
      current_page,
      total_record_on_current_page,
    } = this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Notable Achievement
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold mr-6">
                          Total : {total_record} Records
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          changeKeyword={this.changeKeyword}
                          reset={this.reset}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddAchievement();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Achievement
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Notable Achievement Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/add/edit/delete Notable Achievement.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span onClick={() => this.sortBy("name")}>
                                    Title
                                    {this.state.sort === "name" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>Active Status</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span onClick={() => this.sortBy("date")}>
                                    Created On
                                    {this.state.sort === "date" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>Action</span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && achievement.length === 0 ? (
                              <AdministratorEmpty />
                            ) : (
                              <AchievementGrid
                                achievement={this.props.achievement}
                                modalActive={this.state.active}
                                toggleActive={this.toggleAcitve}
                                status_id={this.state.forum_id}
                                Update={this.UpdateStatus}
                                msg={this.state.msg}
                                deleted={this.deletedAchievement}
                                toggle={this.toggle}
                                modal={this.state.modal}
                                delete_id={this.state.delete_id}
                                toggleEditAchievement={
                                  this.toggleEditAchievement
                                }
                                toggleDetail={this.toggleDetail}
                                detailModal={this.state.detailModal}
                                detail={this.state.detail}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddAchievement
            isShow={this.state.isShow}
            toggleAddAchievement={this.toggleAddAchievement}
            editData={this.state.data}
            edit={this.state.edit}
            id={this.state.id}
            toggleEditAchievement={this.toggleEditAchievement}
            setEditDataNull={() => this.setState({ data: null })}
            sort={this.state.sort}
            order={this.state.order}
            keyword={this.state.keyword}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: getAchievement(state).loading,
  achievement: getAchievement(state).achievement,
  pagination: getAchievement(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAchievements: (param) => dispatch(loadAchievements(param)),
  editAchievement: (id, data, callback) =>
    dispatch(editAchievement(id, data, callback)),
  deleteAchievement: (id, callback) =>
    dispatch(deleteAchievement(id, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryTable)
);
