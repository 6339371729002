import React, { Component } from "react";
import PropTypes from "prop-types";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecords: "",
      pageLimit: "",
      totalPages: "",
      currentPage: "",
      initialPage: "",
      pagesToShow: ""
    };
  }

  componentDidMount() {
    this.setState({
      totalRecords: this.props.totalRecords,
      pageLimit: this.props.pageLimit,
      totalPages: Math.ceil(this.props.totalRecords/this.props.pageLimit),
      pagesToShow: this.props.pagesToShow || 5,
      currentPage: this.props.initialPage || 1
    });
  
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      totalRecords: nextProps.totalRecords,
      pageLimit: nextProps.pageLimit,
      totalPages:Math.ceil(this.props.totalRecords/this.props.pageLimit),
      pagesToShow: nextProps.pagesToShow || 5
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.totalRecords !== prevState.totalRecords ||
      this.state.pageLimit !== prevState.pageLimit
    ) {
      this.setPage(this.state.currentPage);
    }
  
  }

  setPage(page) {
    var { totalRecords, pageLimit, totalPages } = this.state;
    if (page < 1) {
      page = 1;
    } 
    else if (page > totalPages) {
      page = totalPages;
    }
    this.setState({
      currentPage: page
    });
    this.props.onChangePage({
      pageLimit,
      totalPages,
      page,
    });
  }

  getPager() {
    var { pagesToShow, currentPage, totalPages } = this.state;
    var pages = [],
      startFromNumber;

    if (totalPages <= pagesToShow) {
      startFromNumber = 1;
      pagesToShow = totalPages;
    } else {
      if (currentPage <= Math.ceil(pagesToShow / 2)) {
        startFromNumber = 1;
      } else if (
        currentPage + Math.floor((pagesToShow - 1) / 2) >=
        totalPages
      ) {
        startFromNumber = totalPages - (pagesToShow - 1);
      } else {
        startFromNumber = currentPage - Math.floor(pagesToShow / 2);
      }
    }

    for (let i = 1; i <= pagesToShow; i++) {
      pages.push(startFromNumber++);
    }
    
    return {
      currentPage,
      totalPages,
      pages
    };
  }

  render() {
    if (!this.state.totalRecords || this.state.totalPages === 1) return null;
    var pager = this.getPager();

    return ( 
      <div className="datatable-pager datatable-paging-loaded">
        <ul className="datatable-pager-nav my-2 mb-sm-0">
          <li>
            <a title="First"  disabled={pager.currentPage === 1 ? true : false}onClick={() => this.setPage(1)} className="datatable-pager-link datatable-pager-link-first" data-page="1">
              <i className="flaticon2-fast-back"></i>
            </a>
          </li>
          <li>
            <a title="Previous" disabled={pager.currentPage === 1 ? true : false} onClick={() => this.setPage(pager.currentPage - 1)} className="datatable-pager-link datatable-pager-link-prev">
              <i className="flaticon2-back"></i>
            </a>
          </li>
          {pager.pages.map((page, index) => (
            <li key={index}>
          <a className={pager.currentPage === page ?"datatable-pager-link datatable-pager-link-number datatable-pager-link-number datatable-pager-link-active":"datatable-pager-link"} onClick={() => this.setPage(page)} title={page}>{page}</a>
            </li>
          ))}
          <li>
            <a title="Next"  disabled={pager.currentPage === pager.totalPages ? true : false} onClick={() => this.setPage(pager.currentPage + 1)} className="datatable-pager-link datatable-pager-link-next">
              <i className="flaticon2-next"></i>
            </a>
          </li>
          <li>
            <a title="Last" disabled={pager.currentPage === pager.totalPages ? true : false} onClick={() => this.setPage(pager.totalPages)} className="datatable-pager-link datatable-pager-link-last" data-page="35">
              <i className="flaticon2-fast-next"></i>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
  initialPage: PropTypes.number,
  pagesToShow: PropTypes.number,
  onChangePage: PropTypes.func
};

export default Pagination;
