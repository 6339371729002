import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

class AchievementDetail extends Component {
  render() {
    const detail = this.props.detail;
    console.log("detail", detail);
    return (
      <>
        <div
          className={
            this.props.detailModal
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Achievement Detail</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleDetail}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              style={{ marginTop: "10px" }}
              width={300}
              height={200}
              src={
                detail.image && process.env.REACT_APP_CDN + detail.image.small
              }
              alt=""
            />
          </div>

          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Title:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.name ? detail.name : "--"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Active Status:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.isActive ? "Active" : "InActive"}
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
              href="#"
              className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              Description:{" "}
            </a>
            <span className="text-muted font-weight-bold education-margin">
              {detail.description ? detail.description : "--"}
            </span>
          </div>
        </div>
        {this.props.detailModal ? (
          <div
            className="offcanvas-overlay"
            onClick={this.props.toggleDetail}
          ></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default AchievementDetail;
