import React from "react";
import Form from "../../common/form/form";
import Joi, { callStack } from "joi-browser";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { Link } from "react-router-dom";
import {
  loadAchievements,
  addAchievement,
  uploadImage,
  editAchievement,
} from "../../../store/notableAchievement";
import close from "../../../include/media/close.svg";
import { getDonor, updateDonation } from "../../../store/donation";

const initialState = {
  loading: false,
  status: "",
  data: {
    amount: "",
  },
  errors: {},
  disable: false,
};
class UpdateStatus extends Form {
  state = initialState;

  schema = {
    amount: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Amount is required.";
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };

    let payload = {
      amount: parseInt(data.amount),
      status: this.state.status,
    };

    this.props.updateDonation(this.props.id, payload, (res) => {
      this.setState({ loading: false });
      if (res && res.status === 200) {
        this.props.getDonor({
          sort: this.props.sort,
          order: this.props.order,
          page: this.props.currentPage,
          keyword: this.props.keyword,
        });
        this.toggleAddClose();
        return toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        return toast(
          <AlertError message={res && res.data && res.data.message} />
        );
      }
    });
  };

  toggleAddClose = () => {
    this.setState(initialState);
    this.props.toggleStatusUpdate();
  };

  toggleActive = () => {
    this.setState({
      active: !this.state.active,
    });
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Update Donation Status</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => this.toggleAddClose()}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("amount", "Amount ($)", "number")}
            <div class="form-group">
              <label for="name" class="form-label">
                Status
              </label>
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="pending"
                  onChange={(e) => this.setState({ status: e.target.value })}
                  checked={this.state.status === "pending"}
                />
                <label class="form-check-label" htmlFor="flexRadioDefault2">
                  Pending
                </label>
              </div>
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="paid"
                  onChange={(e) => this.setState({ status: e.target.value })}
                  checked={this.state.status === "paid"}
                />
                <label class="form-check-label" htmlFor="flexRadioDefault3">
                  Paid
                </label>
              </div>
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault4"
                  value="failed"
                  onChange={(e) => this.setState({ status: e.target.value })}
                  checked={this.state.status === "failed"}
                />
                <label class="form-check-label" htmlFor="flexRadioDefault4">
                  Failed
                </label>
              </div>
            </div>

            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDonor: (params) => dispatch(getDonor(params)),
  updateDonation: (id, data, callback) =>
    dispatch(updateDonation(id, data, callback)),
});
export default connect(null, mapDispatchToProps)(UpdateStatus);
