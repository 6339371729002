import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import close from "../../include/media/close.svg";
import {
  loadAllAdministrator,
  addAdministrator,
  getAdministrator,
} from "../../store/administrator";

import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  informationSaved,
  failed,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState = {
  data: {
    first_name: "",
    last_name: "",
    password: "",
    email: "",
  },
  errors: {
    first_name: "",
    last_name: "",
    password: "",
    email: "",
  },
  master: false,
};
class AddAdministrator extends Form {
  state = initialState;
  schema = {
    first_name: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),
    last_name: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
            default:
          }
        });
        return errors;
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
  };
  toggleMaster = () => {
    this.setState({
      master: !this.state.master,
    });
  };

  doSubmit = () => {
    const { first_name, last_name, password, email } = this.state.data;
    const { master } = this.state;
    var formdata = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      password: password,
      role: master ? "masteradmin" : "admin",
    };
    this.props.addAdministrator(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({ page: this.props.initialPage });
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  toggleAddClose = () => {
    this.setState(initialState);
    this.props.toggleAddUser();
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Administrator</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("first_name", "First Name")}
            {this.renderInput("last_name", "Last Name")}
            {this.renderInput("email", "Email")}
            {this.renderPasswordInput("password", "Password")}
            <div className="form-group">
              <label>Master Admin</label>
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    defaultChecked={this.state.master}
                    onClick={this.toggleMaster}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state).administrator,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  addAdministrator: (param, callback) =>
    dispatch(addAdministrator(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAdministrator);
