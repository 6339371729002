import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import { searchUser } from "./user";
//import moment from "moment";

const slice = createSlice({
    name: "events",
    initialState: {
        events: [],
        pagination: {},
        loading: false,
        lastFetch: null,
    },
    reducers: {
        eventRequested: (administrator, action) => {
            administrator.loading = true;
        },

        eventReceived: (administrator, action) => {
            administrator.events = action.payload.data;
            administrator.pagination = action.payload.pagination;
            administrator.loading = false;
            administrator.lastFetch = Date.now();
        },

        eventRequestFailed: (administrator, action) => {
            administrator.loading = false;
        },
    },
});

export const {
    eventRequested, eventReceived, eventRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "events/";


export const loadEvents = (params, callback) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "search",
            params,
            onStart: eventRequested.type,
            onSuccess: eventReceived.type,
            onError: eventRequestFailed.type,
            callback
        })
    );
};

export const updateEventStatus = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "changeStatus/" + id,
            method: "PUT",
            data,
            callback,
        })
    );
};
export const updateEventFeatured = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "featured/" + id,
            method: "PUT",

            callback,
        })
    );
};

export const deleteEvent = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "DELETE",
            callback,
        })
    );
};

export const uploadCoverImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadCoverImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const addEvent = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};

export const editEvent = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const getEvents = createSelector(
    (state) => state.entities.events,
    (events) => events
);
