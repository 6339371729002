import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import { searchUser } from "./user";
//import moment from "moment";

const slice = createSlice({
    name: "donation",
    initialState: {
        donor: [],
        pagination: {},
        loading: false,
        lastFetch: null,
    },
    reducers: {
        donorRequested: (administrator, action) => {
            administrator.loading = true;
        },

        donorReceived: (administrator, action) => {
            administrator.donor = action.payload.data;
            administrator.pagination = action.payload.pagination;
            administrator.loading = false;
            administrator.lastFetch = Date.now();
        },

        donorRequestFailed: (administrator, action) => {
            administrator.loading = false;
        },
    },
});

export const {
    donorRequested, donorReceived, donorRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "donation/";


export const getDonor = (params) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url,
            params,
            onStart: donorRequested.type,
            onSuccess: donorReceived.type,
            onError: donorRequestFailed.type,
        })
    );
};


export const updateDonation = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "PUT",
            data,
            callback,
        })
    );
};



export const getDonation = createSelector(
    (state) => state.entities.donation,
    (donation) => donation
);
