import { combineReducers } from "redux";

import adminReducer from "./admin";
import administratorReducer from "./administrator";
import userReducer from "./user";
import eventsReducer from "./events";
import forumReducer from "./forum";
import basicReducer from "./basic";
import achievementReducer from "./notableAchievement";
import donationReducer from "./donation";





export default combineReducers({
  admin: adminReducer,
  administrator: administratorReducer,
  users: userReducer,
  events: eventsReducer,
  forums: forumReducer,
  basic: basicReducer,
  notableAchievement: achievementReducer,
  donation: donationReducer

});
