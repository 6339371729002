import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import { searchUser } from "./user";
//import moment from "moment";

const slice = createSlice({
    name: "notableAchievement",
    initialState: {
        achievement: [],
        pagination: {},
        loading: false,
        lastFetch: null,
    },
    reducers: {
        achievementRequested: (administrator, action) => {
            administrator.loading = true;
        },

        achievementReceived: (administrator, action) => {
            administrator.achievement = action.payload.data;
            administrator.pagination = action.payload.pagination;
            administrator.loading = false;
            administrator.lastFetch = Date.now();
        },

        achievementRequestFailed: (administrator, action) => {
            administrator.loading = false;
        },
    },
});

export const {
    achievementRequested, achievementReceived, achievementRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "notableAchievements/";


export const loadAchievements = (params) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url,
            params,
            onStart: achievementRequested.type,
            onSuccess: achievementReceived.type,
            onError: achievementRequestFailed.type,
        })
    );
};

export const deleteAchievement = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "DELETE",
            callback,
        })
    );
};

export const uploadImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const addAchievement = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};

export const editAchievement = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const getAchievement = createSelector(
    (state) => state.entities.notableAchievement,
    (notableAchievement) => notableAchievement
);
