import React, { Component } from "react";

class AdministratorEmpty extends Component {
  	render(){
		return(
			<tbody className="datatable-body">
				<tr data-row="10" className="datatable-row" style={{"left": "0px"}}>
					<td className="datatable-cell">
						<span style={{"width": "145px"}}>
							<div className="font-weight-bolder font-size-lg mb-0">No record found</div>
						</span>
					</td>
					<td className="datatable-cell">
						<span style={{"width": "145px"}}>
							<div className="font-weight-bolder font-size-lg mb-0"></div>
						</span>
					</td>
					<td className="datatable-cell">
		                <span style={{ width: "250px"}}>
		                  <div className="font-weight-bolder font-size-lg mb-0">
		                  </div>
		                </span>
             		</td>
					<td className="datatable-cell">
						<span style={{"width": "145px"}}>
							<div className="font-weight-bolder font-size-lg mb-0"></div>
						</span>
					</td>
					<td className="datatable-cell">
						<span style={{ width: "80px" }}>
							<div className="font-weight-bolder font-size-lg mb-0"></div>
						</span>
              		</td>
					<td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
						<span style={{"overflow": "visible", "position": "relative", "width": "145px"}}>
						</span>
					</td>
				</tr>
        
			</tbody>
		)
    }
}
export default AdministratorEmpty;