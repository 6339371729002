import React from "react";
import Joi from "joi-browser";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/form/form";
import { adminSignIn } from "../../store/admin";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import SubmitBtn from "../common/form/submitBtn";
import logo from "./../../include/media/favicon.png";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  emailRequired,
} from "../common/misc";
import {
  setJwt,
  setAdminId,
  setAdminName,
  setAdminEmail,
  setAdminRole,
} from "../services/localStorageServices";

class SignIn extends Form {
  state = {
    label: "Sign In",
    loader: false,
    btnClass: "btn btn-videostar font-weight-bolder",
    data: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
            default:
          }
        });
        return errors;
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
  };

  doSubmit = () => {
    console.log("dcmss");
    this.setState({ loader: true, label: "" });
    const { data } = this.state;
    this.props.adminSignIn(
      {
        email: data.email,
        password: data.password,
      },
      this.callback
    );
  };

  callback = (res) => {
    this.setState({
      loader: false,
      label: "Sign In",
      btnClass: "btn btn-videostar font-weight-bolder",
    });
    if (res) {
      if (res.status !== 200) {
        this.setState({
          loader: false,
          label: "Sign In",
          btnClass: "btn btn-videostar font-weight-bolder",
        });
        toast(<AlertError message={res.data.message} />);
      } else if (res.status === 200) {
        setJwt(res.data.data.jwt, res.data.data.refreshToken);
        setAdminId(res.data.data._id);
        setAdminName(res.data.data.firstName + " " + res.data.data.lastName);
        setAdminEmail(res.data.data.email);
        setAdminRole(res.data.data.role);
        localStorage.setItem("admin_details", JSON.stringify(res.data.data));
        this.props.history.push("/administrators");
      }
    }
  };

  render() {
    return (
      <div
        className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
              <Link to="/" className="mb-15 text-center">
                <img src={logo} className="max-h-75px logo-img" alt="" />
              </Link>
              <div className="login-form login-signin">
                <div className="text-center mb-10 mb-lg-20">
                  <h2 className="font-weight-bold">Sign In</h2>
                  <p className="text-muted font-weight-bold">
                    Enter your username and password
                  </p>
                </div>
                <form action="" onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "Email")}
                  {this.renderPasswordInput("password", "Password")}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                    <div className="checkbox-inline"></div>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                    <div className="my-3 mr-2">
                      <span className="text-muted mr-2"></span>
                      <Link
                        to={"javascript:void(0)"}
                        className="font-weight-bold"
                      ></Link>
                    </div>
                    <SubmitBtn
                      label={this.state.label}
                      loading={this.state.loader}
                      btnClass={this.state.btnClass}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7"
          style={{
            backgroundColor: "#C5A28B",
          }}
        >
          <div className="d-flex flex-column-fluid flex-lg-center">
            <div className="d-flex flex-column mr-auto login-right-content">
              <h3 className="display-3 font-weight-bold my-7 text-white">
                {" "}
                Welcome to <br /> GoldnLink Admin Panel!
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  adminSignIn: (param, callback) => dispatch(adminSignIn(param, callback)),
  //   adminRefresh: (callback) => dispatch(refreshAdmin(callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(SignIn));
